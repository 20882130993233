import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import * as moment from 'moment';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import ComponentParser from '../components/component.parser';
import Content from '../components/component.content';
import Latest from '../components/component.latest';
import CookieNotice from "../components/component.cookie-notice";

const PostTemplate = ({ data }) => {
    const { title, date, content, blocks, featuredImage, seo, language, translations, slug } = data.wordpress.post;
   
    // Sets the banner image to the image supplied else uses a default banner image
    const postImage = featuredImage ? featuredImage.node.imageFile.childImageSharp.fluid : data.file.childImageSharp.fluid;
    const postAltTag = featuredImage ? featuredImage.altText : 'WinTech Racing logo';

    let pageLanguage = 'EN';
    if(typeof language !== "undefined") {
        pageLanguage = language.code;
    }

    return (
        <Layout language={language} translations={translations}>
            <SEO title={title} seo={seo} image={featuredImage} translations={translations} slug={slug} language={language} />

            <div className="u-bg--seashell xs-pt-30 xs-pb-30 md-pt-60 md-pb-60">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <Img className="xs-mb-20 md-mb-0" fluid={postImage} alt={postAltTag} />
                        </div>
                        <div className="col-sm-6">
                            <Link className="u-text--link u-text--uppercase" to="/category/news/">News</Link>
                            <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
                            <span className="u-text--dove-grey">{moment(date).format('DD MMM YYYY')}</span>
                        </div>
                    </div>
                </div>
            </div>

            {content &&
                <Content content={content} />
            }
            {blocks &&
                <ComponentParser blocks={blocks} />
            }

            <Latest language={language} />
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        file(relativePath: { eq: "default-post-image.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 535) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        wordpress {
            post(id: $id) {
                title
                date
                content
                language {
                    code
                    locale
                }
                translations {
                    uri
                    language {
                        code
                        locale
                    }
                }
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                seo {
                    ...SeoPostFields
                }
                ...PostBlocks
            }
        }
    }
`;

export default PostTemplate;